var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"my-0"},[_c('Alertize'),_c('v-layout',{attrs:{"column":""}},[_c('v-form',{ref:"form",attrs:{"justify":"center","align":"center","lazy-validation":"","readonly":this.isRolReport},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('CardTextField',{attrs:{"rules":[
								_vm.getRules.isRequired,
								_vm.getRules.isMinLength,
							],"hint":_vm.$t('advertisers.fields.id'),"reference":"id","placeholder":_vm.$t('advertisers.fields.id'),"label":_vm.$t('advertisers.fields.id'),"required":true,"disabled":true,"counter":false,"error_messages":_vm.getError('id'),"dataCy":"id"},model:{value:(_vm.advertiser.external_id),callback:function ($$v) {_vm.$set(_vm.advertiser, "external_id", $$v)},expression:"advertiser.external_id"}})],1)],1),(_vm.isAccountRappi && _vm.isBefore)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardAutocomplete',{attrs:{"rules":_vm.rules.rulesCountry,"items":_vm.getCountrys,"item_text":"value","item_value":"id","reference":"country_id","hint":_vm.$t('advertisers.fields.country'),"label":_vm.$t('advertisers.fields.country'),"placeholder":_vm.$t('advertisers.fields.country'),"multiple":false,"disabled":_vm.isDisabledCountry,"required":true,"error_messages":_vm.getError('country_id')},model:{value:(_vm.advertiser.country_id),callback:function ($$v) {_vm.$set(_vm.advertiser, "country_id", $$v)},expression:"advertiser.country_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardAutocomplete',{attrs:{"rules":_vm.rules.rulesMakers,"items":_vm.getMarkers,"item_text":"value","item_value":"id","reference":"maker_name","return_object":"","persistent_hint":true,"hint":_vm.$t('advertisers.hint.maker'),"label":_vm.$t('advertisers.fields.maker'),"placeholder":_vm.$t('advertisers.fields.maker'),"multiple":false,"required":true,"disabled":_vm.isDisabledMakers,"error_messages":_vm.getError('maker_name')},model:{value:(_vm.advertiser.maker),callback:function ($$v) {_vm.$set(_vm.advertiser, "maker", $$v)},expression:"advertiser.maker"}})],1)],1):_vm._e(),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardTextField',{attrs:{"rules":[
								_vm.getRules.isRequired,
								_vm.getRules.isMinLength,
								_vm.getRules.isMaxCustomLength(
									this.advertiser.name,
									181
								),
							],"hint":_vm.$t('advertisers.fields.name'),"reference":"name","placeholder":_vm.$t('advertisers.fields.name'),"label":_vm.$t('advertisers.fields.name'),"counter":181,"required":true,"error_messages":_vm.getError('name'),"disabled":_vm.isDisabledName,"dataCy":"name"},model:{value:(_vm.advertiser.name),callback:function ($$v) {_vm.$set(_vm.advertiser, "name", $$v)},expression:"advertiser.name"}})],1),(_vm.isAccountRappi && _vm.show_trademark)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardAutocomplete',{attrs:{"rules":[_vm.getRules.isRequired],"items":_vm.getTradeMarks,"item_text":"value","item_value":"id","reference":"trademark_id","persistent_hint":true,"hint":_vm.$t('advertisers.hint.trademark'),"label":_vm.$t('advertisers.fields.trademark'),"placeholder":_vm.$t('advertisers.fields.trademark'),"multiple":false,"required":true,"disabled":_vm.advertiser.hasCampaign,"error_messages":_vm.getError('trademark_id')},on:{"focus":_vm.fetchTradeMarks},model:{value:(_vm.advertiser.trademark_id),callback:function ($$v) {_vm.$set(_vm.advertiser, "trademark_id", $$v)},expression:"advertiser.trademark_id"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardAutocomplete',{attrs:{"rules":[
								_vm.getRules.isRequired,
								_vm.getRules.isNumber,
							],"items":_vm.getCategories,"item_text":"name","item_value":"id","reference":"category_id","hint":_vm.$t('advertisers.fields.category'),"label":_vm.$t('advertisers.fields.category'),"placeholder":_vm.$t('advertisers.fields.category'),"multiple":false,"required":true,"error_messages":_vm.getError('category_id'),"dataCy":"category_id"},model:{value:(_vm.advertiser.category_id),callback:function ($$v) {_vm.$set(_vm.advertiser, "category_id", $$v)},expression:"advertiser.category_id"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardTextFieldToolTip',{attrs:{"rules":[
								_vm.getRules.isRequired,
								_vm.getRules.isDomain,
							],"hint":_vm.$t('advertisers.fields.domain'),"reference":"domain","placeholder":_vm.$t('advertisers.fields.domain'),"label":_vm.$t('advertisers.fields.domain'),"required":true,"error_messages":_vm.getError('domain'),"tooltip_message":_vm.$t('advertisers.labels.domainTooltip'),"dataCy":"domain"},model:{value:(_vm.advertiser.domain),callback:function ($$v) {_vm.$set(_vm.advertiser, "domain", $$v)},expression:"advertiser.domain"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"6"}},[_c('CardTextFieldToolTip',{attrs:{"hint":_vm.$t('advertisers.fields.app_bundle'),"reference":"app_bundle","placeholder":_vm.$t('advertisers.fields.app_bundle'),"label":_vm.$t('advertisers.fields.app_bundle'),"tooltip_message":_vm.$t('advertisers.labels.appBundleTooltip'),"dataCy":"app_bundle"},model:{value:(_vm.advertiser.app_bundle),callback:function ($$v) {_vm.$set(_vm.advertiser, "app_bundle", $$v)},expression:"advertiser.app_bundle"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3"}},[_c('CardSwitch',{attrs:{"inset":true,"reference":"active","label":`${
								_vm.advertiser.active
									? _vm.$t('common.fields.active')
									: _vm.$t('common.fields.inactive')
							}`,"color":"success","dataCy":"active"},on:{"change":_vm.toggleStatus},model:{value:(_vm.advertiser.active),callback:function ($$v) {_vm.$set(_vm.advertiser, "active", $$v)},expression:"advertiser.active"}})],1)],1),_c('v-divider',{staticClass:"ma-4"}),_c('v-row',{attrs:{"no-gutters":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"9"}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"0","outlined":"","tile":"","color":"rgb(0, 0, 0, 0.0)"}},[_c('v-card-text',{staticClass:"secondary--text info-message",attrs:{"align":"start"}},[_vm._v(" "+_vm._s(_vm.$t("common.labels.mandatory"))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8","lg":"3"}},[_c('v-card-actions',[_c('v-spacer'),_c('CardActions',{attrs:{"showBtns":['submit', 'cancel'],"texts":{ submit: 'save' },"disabled":{ submit: this.isRolReport }},on:{"action":_vm.handleAction}})],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }